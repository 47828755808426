<template>
  <CBox v-if="isLoadingPage"></CBox>
  <CBox
    v-else-if="currentUser && !currentUser.urole_id"
    d="flex"
    justifyContent="space-between"
    flexWrap="wrap"
  >
    <CBox
      borderRadius="10px"
      mt="5"
      :w="['100%', '100%', '74%', '74%']"
      bg="white"
      shadow="sm"
      rounded="md"
      py="24px"
      :px="['10px', '24px', '24px', '24px']"
      position="relative"
    >
      <ImageCroppie
        :isModalOpen="isModalOpen"
        :urlImage="logoPreview"
        @closeModal="closeModal"
        @cropCompleted="handleCropCompleted"
        @cropBlob="handleCropBlob"
      />
      <CBox>
        <CText fontStyle="extrabold" fontSize="3xl" fontWeight="semibold"
          >Create Club</CText
        >
        <form method="post">
          <CBox px="4">
            <CFormControl my="4">
              <CFormLabel d="block">Profile</CFormLabel>
              <CInputGroup>
                <CInput
                  type="file"
                  accept="image/*"
                  @change.native="handleImageUpload"
                  required
                  d="none"
                  ref="fileInput"
                  id="fileInput"
                />
                <CBox>
                  <CFormLabel
                    for="fileInput"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    position="relative"
                  >
                    <CImage
                      :src="
                        croppedImageData
                          ? croppedImageData
                          : require('@/assets/img/empty-file-image.svg')
                      "
                      boxSize="150px"
                      borderRadius="5"
                      w="10"
                    />
                    <CImage
                      :src="require('@/assets/icon/icon-plus.svg')"
                      mr="1"
                      position="absolute"
                      right="0"
                      bottom="0"
                      fontSize="12px"
                      color="white"
                      bg="bgButtonClub"
                      borderRadius="full"
                      w="20px"
                    />
                  </CFormLabel>
                  <CFormHelperText textAlign="center">
                    (300 x 300)
                  </CFormHelperText>
                  <CFormHelperText textAlign="center">
                    Maximum file size: 2Mb
                  </CFormHelperText>
                </CBox>
                <CFormHelperText
                  v-if="fileSize > 1600000"
                  color="red.300"
                  fontSize="sm"
                  ml="2"
                  my="auto"
                >
                  File size too large
                </CFormHelperText>
              </CInputGroup>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="name">Name</CFormLabel>
              <CInput id="name" v-model="dataClub.name" required />
              <CFormHelperText
                :color="dataClub.name.length > 50 ? '#E82609' : '#ACACAC'"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  dataClub.name.length > 50
                    ? "Name too long."
                    : "Maximum 50 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="location">Location</CFormLabel>
              <CInput id="location" v-model="dataClub.location" required />
              <CFormHelperText
                :color="dataClub.location.length > 50 ? '#E82609' : '#ACACAC'"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  dataClub.location.length > 50
                    ? "Location too long."
                    : "Maximum 50 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="description">Description</CFormLabel>
              <textarea
                id="description"
                v-model="dataClub.description"
                rows="3"
                class="textarea-club"
                required
              ></textarea>
              <CFormHelperText
                :color="
                  dataClub.description.length > 1500 ? '#E82609' : '#ACACAC'
                "
                maxW="300px"
                fontSize="12px"
              >
                {{
                  dataClub.description.length > 1500
                    ? "Description too long."
                    : "Maximum 1500 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="rule">Rule to join this club</CFormLabel>
              <textarea
                id="rule"
                v-model="dataClub.rule"
                rows="3"
                class="textarea-club"
                required
              >
              </textarea>
              <CFormHelperText
                :color="dataClub.rule.length > 1500 ? '#E82609' : '#ACACAC'"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  dataClub.rule.length > 1500
                    ? "Rule too long."
                    : "Maximum 1500 character."
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="type" d="block" m="0" p="0">Type</CFormLabel>
              <CButton
                v-for="(type, i) in types"
                :key="i"
                :bg="dataClub.type === type ? '#E1F3FF' : 'white'"
                :color="dataClub.type === type ? '#29AAFE' : 'black'"
                @click.native="setType(type)"
                :_hover="{ opacity: 0.8 }"
                :border="
                  dataClub.type === type
                    ? '#29AAFE 1px solid'
                    : '#EFF1F5 1px solid'
                "
                mr="2"
                mt="2"
                mb="2"
                fontWeight="400"
              >
                <CImage
                  :src="
                    dataClub.type === type
                      ? require('@/assets/icon/icon-' +
                          checkType(type).toLowerCase() +
                          '-active.svg')
                      : require('@/assets/icon/icon-' +
                          checkType(type).toLowerCase() +
                          '.svg')
                  "
                  mr="2"
                  w="20px"
                />
                {{ checkType(type) }}</CButton
              >
            </CFormControl>
            <CFormControl mb="4" :w="['100%', '50%', '50%', '50%']">
              <CFormLabel for="language" m="0" p="0">Language</CFormLabel>
              <CText color="#ACACAC" maxW="300px" fontSize="12px" mb="2">
                Select one or more options
              </CText>
              <CBox>
                <CButton
                  v-for="(language, i) in languages"
                  :key="i"
                  :bg="
                    dataClub.language.includes(language) ? '#E1F3FF' : 'white'
                  "
                  :color="
                    dataClub.language.includes(language) ? '#29AAFE' : 'black'
                  "
                  @click.native="setLanguage(language)"
                  :_hover="{ opacity: 0.8 }"
                  :border="
                    dataClub.language.includes(language)
                      ? '#29AAFE 1px solid'
                      : '#EFF1F5 1px solid'
                  "
                  mr="1"
                  mt="1"
                  mb="1"
                  fontWeight="400"
                >
                  <CImage
                    :src="
                      require('@/assets/icon/icon-' +
                        language.toLowerCase().replace('#', 's') +
                        '.svg')
                    "
                    mr="2"
                    w="20px"
                  />
                  {{ language }}</CButton
                >
              </CBox>
            </CFormControl>
            <CFormControl mb="4">
              <CFormLabel for="slug">Slug</CFormLabel>
              <CBox
                :d="['block', 'flex', 'flex', 'flex']"
                alignItems="center"
                :bg="['', '#EFF1F5', '#EFF1F5', '#EFF1F5']"
                w="fit-content"
                :pl="['0', '4', '4', '4']"
                borderRadius="5"
              >
                <CText
                  :fontSize="['12px', '16px', '16px', '16px']"
                  mr="2"
                  fontWeight="300"
                  >https://devcode.gethired.id/clubs/</CText
                >
                <CInput
                  id="slug"
                  v-model="formattedLink"
                  required
                  w="250px"
                  :mt="['2', '0', '0', '0']"
                  placeholder="your-slug"
                />
              </CBox>
              <CFormHelperText
                v-if="dataClub.link.length > 50 || dataClub.link.length <= 3"
                color="#ACACAC"
                maxW="300px"
                fontSize="12px"
              >
                {{
                  dataClub.link.length > 50
                    ? "Slug too long."
                    : 3 >= dataClub.link.length
                    ? "Slug must be 3 characters or longer."
                    : ""
                }}
              </CFormHelperText>
              <CFormHelperText color="red.300" fontSize="12px">
                {{
                  errorMessage === "link already exist"
                    ? "Slug already exist."
                    : errorMessage === "link must be alphabet characters only"
                    ? "Slug must be alphabet only."
                    : ""
                }}
              </CFormHelperText>
            </CFormControl>
            <CFormControl mb="4">
              <CBox
                :d="['block', 'flex', 'flex', 'flex']"
                alignItems="center"
                w="fit-content"
              >
                <CCheckbox
                  :value="dataClub.private"
                  :isChecked="dataClub.private"
                  @change="setPrivate(dataClub.private)"
                  d="flex"
                  alignItems="center"
                >
                  Make your club private
                  <CImage
                    :src="require('@/assets/icon/icon-lock.svg')"
                    w="20px"
                    d="inline-block"
                  />
                </CCheckbox>
              </CBox>
            </CFormControl>
            <CFormControl mb="4">
              <CBox
                :d="['block', 'flex', 'flex', 'flex']"
                alignItems="center"
                w="fit-content"
              >
                <CCheckbox
                  :value="!dataClub.isNeedApproval"
                  :isChecked="!dataClub.isNeedApproval"
                  @change="setIsNeedApproval(dataClub.isNeedApproval)"
                  d="flex"
                  alignItems="center"
                >
                  Accept Membership Requests Automatically
                </CCheckbox>
              </CBox>
            </CFormControl>
            <CButton
              bg="bgButtonClub"
              color="white"
              fontWeight="600"
              :_hover="{ opacity: '0.8' }"
              @click.native="postClub"
              :disabled="isCreateClubButtonDisabled || isLoading"
              >{{ !isLoading ? "Create Club" : "Loading..." }}</CButton
            >
            <CText
              v-if="errorMessage == 'cannot create more than 3 clubs'"
              d="inline-block"
              color="red.300"
              ml="2"
            >
              Cannot create more than 3 clubs.
            </CText>
          </CBox>
        </form>
      </CBox>
    </CBox>
    <CBox v-if="!isLoading" :w="['100%', '100%', '25%', '25%']">
      <SidebarListClub />
    </CBox>
  </CBox>
</template>

<script>
import {
  CBox,
  CText,
  CFormControl,
  CFormLabel,
  CInput,
  CButton,
  CFormHelperText,
  CInputGroup,
  CImage,
} from "@chakra-ui/vue";
import Swal from "sweetalert2";
import {
  POST_CLUB,
  POST_CLUB_IMAGE,
  GET_CLUB_REQUESTS_TOTAL,
  GET_CLUB_TOTAL,
  GET_CLUB_TOTAL_JOINED,
} from "@/store/club.module";
import ImageCroppie from "@/components/modal/ImageCroppie.vue";
import SidebarListClub from "@/components/sidebar/ListClub.vue";
import config from "@/ConfigProvider.js";

export default {
  name: "CreateClub",
  components: {
    CBox,
    CText,
    CFormControl,
    CFormLabel,
    CInput,
    CButton,
    CFormHelperText,
    CInputGroup,
    ImageCroppie,
    CImage,
    SidebarListClub,
  },
  metaInfo() {
    return {
      title: "Create Club",
      titleTemplate: "Devcode - %s",
    };
  },
  data() {
    return {
      isLoading: false,
      isLoadingPage: true,
      logoPreview: "",
      fileSize: 0,
      dataClub: {
        logo: "",
        name: "",
        location: "",
        type: "komunitas",
        link: "",
        description: "",
        rule: "",
        language: [],
        private: false,
        isNeedApproval: true,
      },
      languages: [
        "Javascript",
        "PHP",
        "Go",
        "Python",
        "Java",
        "C#",
        "Rust",
        "Ruby",
        "Dart",
        "Kotlin",
        "C",
        "C++",
        "Typescript",
      ],
      types: ["komunitas", "tim lomba", "kampus", "perusahaan"],
      errorMessage: "",
      cropperOptions: 2,
      isModalOpen: false,
      croppedImageData: null,
      croppedImageBlob: null,
      urlGethired: config.value("GETHIRED_WEB_URL"),
    };
  },
  async mounted() {
    try {
      if (!(this.currentUser && !this.currentUser.urole_id)) {
        Swal.fire({
          title: "Error!",
          text: "Page not found!",
          icon: "error",
        }).then(() => {
          window.history.back();
        });
      } else {
        await this.getData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoadingPage = false;
    }
  },
  methods: {
    async getData() {
      try {
        if (!JSON.parse(this.currentUser).urole_id) {
          await this.$store.dispatch(GET_CLUB_REQUESTS_TOTAL);
          await this.$store.dispatch(GET_CLUB_TOTAL_JOINED);
        }

        await this.$store.dispatch(GET_CLUB_TOTAL);
      } catch (err) {
        console.log(err);
      }
    },
    async postClub() {
      try {
        this.isLoading = true;

        if (this.croppedImageBlob) {
          const imageResponse = await this.$store.dispatch(
            POST_CLUB_IMAGE,
            this.croppedImageBlob
          );

          this.dataClub.logo = imageResponse.data.location;
        }

        const params = {
          data: {
            ...this.dataClub,
          },
        };

        if (this.currentUser) {
          // Call the POST_CLUB API with updated dataClub
          await this.$store.dispatch(POST_CLUB, params);
          Swal.fire({
            title: "Created!",
            text: "Club created successfully!",
            icon: "success",
          }).then(() => {
            this.$router.push("/clubs").then(() => {
              setTimeout(() => {
                window.location.reload(true);
              }, 0);
            });
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage = error.response.data.message;
          console.error(`Error 400: ${this.errorMessage}`);
        } else {
          console.error("Unexpected error:", error.message);
        }
      } finally {
        this.isLoading = false;
      }
    },
    isFormComplete() {
      return (
        this.dataClub.name &&
        this.dataClub.name?.length <= 50 &&
        this.dataClub.location &&
        this.dataClub.location?.length <= 50 &&
        this.dataClub.description &&
        this.dataClub.description?.length <= 1500 &&
        this.dataClub.rule &&
        this.dataClub.rule?.length <= 1500 &&
        this.dataClub.link.length > 3 &&
        this.dataClub.link.length <= 50 &&
        this.dataClub.language.some((isSelected) => isSelected)
      );
    },
    async handleImageUpload(event) {
      const file = event.target.files[0];
      if (file && file.size && file.size <= 1600000) {
        this.fileSize = file.size;

        // Simpan URL gambar sebagai string
        this.logoPreview = URL.createObjectURL(file);

        const formData = new FormData();
        formData.append("file", file);

        // Simpan FormData untuk penggunaan nanti
        this.dataClub.logo = formData;
        this.isModalOpen = true;
      } else if (file.size > 1600000) {
        this.fileSize = file.size;
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleCropCompleted(croppedData) {
      this.croppedImageData = croppedData;
    },
    async handleCropBlob(croppedImageBlob) {
      this.croppedImageBlob = croppedImageBlob;
    },
    setType(type) {
      this.dataClub.type = type;
    },
    setPrivate(isPrivate) {
      this.dataClub.private = !isPrivate;
    },
    setIsNeedApproval(isNeedApproval) {
      this.dataClub.isNeedApproval = !isNeedApproval;
    },
    setLanguage(language) {
      if (this.dataClub.language.includes(language)) {
        const index = this.dataClub.language.indexOf(language);
        this.dataClub.language.splice(index, 1);
      } else {
        this.dataClub.language.push(language);
      }
    },
    checkType(type) {
      return type === "komunitas"
        ? "Community"
        : type === "tim lomba"
        ? "Event"
        : type === "kampus"
        ? "University"
        : "Company";
    },
  },
  computed: {
    isCreateClubButtonDisabled() {
      return !this.isFormComplete();
    },
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    clubs() {
      return this.$store.getters.clubs || [];
    },
    clubTotal() {
      return this.$store.getters.clubTotal || 0;
    },
    clubTotalJoined() {
      return this.$store.getters.clubTotalJoined || 0;
    },
    requestsTotal() {
      return this.$store.getters.requestsTotal || 0;
    },
    formattedLink: {
      get() {
        return this.dataClub.link.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
      },
      set(value) {
        this.$set(
          this.dataClub,
          "link",
          value.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase()
        );
      },
    },
  },
};
</script>
